class DotpadResize {  // eslint-disable-line no-unused-vars

    // 생성자
    constructor(dotPadSDK, fromCellArray, toColCellSize, toRowCellSize) 
    {
        this.dotPadSDK = dotPadSDK;
        this.fromCellArray = fromCellArray;
        this.fromColCellSize = 30;
        this.fromRowCellSize = 10;
        
        this.toColCellSize = toColCellSize;
        this.toRowCellSize = toRowCellSize;
        this.toCellArray = new Uint8Array(toColCellSize*toRowCellSize);
    }
    
    // 데이타 중앙으로 위치
    setResizeCenter() 
    {
        // (1) 데이타 중앙으로 변환 처리 
        this.setResizeData();

        // (2) SDK에 변경 정보 반영
        this.dotPadSDK.writeString = this.dotPadSDK.toHexString(this.toCellArray);
        this.dotPadSDK.numCell = 48;
        this.dotPadSDK.length = 16;
    }

    // 데이타 중앙으로 변환 처리 
    setResizeData()
    {
        // 중앙에 배치할 시작 위치 계산
        const startX = Math.floor((this.toColCellSize - this.fromColCellSize) / 2);
        const startY = Math.floor((this.toRowCellSize - this.fromRowCellSize) / 2);

        // 데이터를 중앙에 삽입
        for (let y = 0; y < this.fromRowCellSize; y++) {
            for (let x = 0; x < this.fromColCellSize; x++) {
                // 원본 데이터에서 읽기
                const originalIndex = y * this.fromColCellSize + x;
                const value = this.fromCellArray[originalIndex];

                // 타겟 데이터의 좌표 계산
                const targetIndex = (startY + y) * this.toColCellSize + (startX + x);

                // 타겟 배열에 데이터 삽입
                this.toCellArray[targetIndex] = value;
            }
        }
    }
}

export {
    DotpadResize
};

